import React from 'react';

const ArrowDown = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="32.143"
    height="18.334"
    viewBox="0 0 32.143 18.334"
  >
    <defs>
      <clipPath>
        <rect
          width="32.143"
          height="18.334"
          transform="translate(0 0)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 -0.001)" clipPath="url(#clip-path)">
        <path
          d="M32.245.317,16.316,18.275.386.317"
          transform="translate(-0.244 -0.209)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="3"
        />
      </g>
    </g>
  </svg>
);

export default ArrowDown;
